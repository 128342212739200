/*CSS FILE */

.header {
	top: 0;
	height: 70px;
	color: white;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	padding-left: 20px;
	width: 100%;
}
.text_toggle {
	font-family: "Open Sans";
	font-size: 12.5px;
}
.tooltip {
	font-size: 15px !important;
	width: 250px !important;
}

.title-formula {
	color: #eb7022;
	font-family: "Open Sans";
	font-size: 32px;
	text-align: center;
	width: 320px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 80px;
}

.under-title-formula {
	color: #b5b5b5;
	font-family: "Open Sans";
	font-size: 17px;
	font-style: italic;
	text-align: center;
	width: 320px;
	font-weight: 300;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
}

.form-big {
	margin-top: 60px;
	width: 320px;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	padding-left: 45px;
}

.img-logo {
	height: 60px;
}

.img-picto {
	width: 320px;
	margin-top: 30px;
}

.div-central {
	width: 320px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 100px;
}

.input-container {
	width: 320px;
	font-size: 15px;
	color: rgb(145, 145, 145);
	margin-bottom: 15px;
	margin-left: auto;
	margin-right: auto;
	font-family: "Open Sans";
}

.flex {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.input-file {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: flex-start;
	width: 275px;
	font-family: "Open Sans";
	font-size: 15px;
	color: rgb(145, 145, 145);
	margin: 0;
	padding-left: 8px;
	padding-top: 20px;
	padding-bottom: 20px;
	border: 1px solid #c5c5c5;
	border-radius: 4px;
	margin-right: 7px;
	cursor: pointer;
}

.upload-icon {
	margin-bottom: 5px;
}

input[type="file"] {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.input-field {
	height: 30px;
	width: 275px;
	font-family: "Open Sans";
	font-size: 15px;
	color: rgb(145, 145, 145);
	margin: 0;
	padding: 0;
	padding-left: 8px;
	border: 1px solid #c5c5c5;
	border-radius: 4px;
	margin-right: 7px;
}

.final-error {
	color: red;
	font-family: "Open Sans";
	font-size: 12.5px;
	margin-top: 10px;
	margin-bottom: 100px;
	margin-left: auto;
	margin-right: auto;
}

.errorClass {
	border: 1px solid red;
	color: red;
}

.info {
	color: #eb7022;
}

.accept-bulletin {
	width: 330;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 300;
	text-align: justify;
	line-height: 24px;
	color: rgb(145, 145, 145);
}

.bold {
	font-weight: 500;
}

.accept-bulletin-flex {
	display: flex;
	flex-direction: row;
	margin-top: 30px;
	width: 300px;
}

.error {
	outline: 1px solid red;
}

.button-submit-patient {
	background-color: #eb7022;
	font-family: "Open Sans";
	font-size: 18px;
	color: white;
	height: 35px;
	width: 150px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	border-color: #eb7022;
	border-radius: 4px;
}

.button-submit-secrétaire {
	background-color: #eb7022;
	font-family: "Open Sans";
	font-size: 18px;
	color: white;
	height: 35px;
	width: 150px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	border-color: #eb7022;
	border-radius: 4px;
}

.footer {
	height: auto;
	background-color: #e9e9e9;
	bottom: 0px;
	width: 100%;
	border-color: #000000;
	display: flex;
	flex-direction: column;
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 20px;
}

a {
	color: #000000;
}
a:link {
	text-decoration: none;
}

a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

a:active {
	text-decoration: underline;
}

.inner-footer {
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
}

.arange-map {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 30px;
	border-radius: 7px;
	overflow: hidden;
}

.img-logo-footer {
	height: 60px;
	width: 110px;
}

.title-footer-orthonov {
	font-family: "Open Sans";
	font-size: 19px;
	line-height: 23px;
	font-weight: 500;
	margin-top: 30px;
}

.texte-footer {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 200;
	font-size: 15px;
	line-height: 23px;
}

.texte-footer-copyright {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 200;
	font-size: 11px;
	line-height: 23px;
}

.space {
	height: 30px;
}
